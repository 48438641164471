/* eslint-disable */
const api = {
  'get:/account': {
    id: 123123123123123123,
    name: '林小均',
    phone: '0987654321',
    phone_verify: 0,
    email: '',
    address: '',
  },
  'post:/account': {
    success: true
  },
  'post:/cart': {
    success: true
  },
  'post:/favorite/1': {
    success: true
  },
  'delete:/favorite/1': {
    success: true
  },
  'get:/cart': {
    user_id: 1231231231231231231,
    amount: 280,
    option: {
      service_type: '',
      pickup_time: '',
      table_number: '',
      shipping: '',
      address: '',
      pay_type: '',
    },
    cart_items: [
      {
        id: 1,
        name: '豬肉滿福堡',
        unit_price: 55,
        quantity: 1,
        options: [
          {
            name: '加料',
            values: [
              {
                name: '起司',
                price: 5
              }
            ],
          },
          {
            name: '口味',
            values: [
              {
                name: '加辣',
                price: 0
              },
            ],
          }
        ],
      },
      {
        id: 2,
        name: '豬肉滿福堡2',
        unit_price: 56,
        quantity: 2,
        options: [
          {
            name: '加料',
            values: [
              {
                name: '起司',
                price: 5
              }
            ],
          },
          {
            name: '口味',
            values: [
              {
                name: '不加辣',
                price: 0
              },
            ],
          }
        ],
      },
      {
        id: 3,
        name: '豬肉滿福堡3',
        unit_price: 57,
        quantity: 3,
        options: [
          {
            name: '加料',
            values: [
              {
                name: '起司',
                price: 5
              }
            ],
          },
          {
            name: '口味',
            values: [
              {
                name: '不加美乃滋',
                price: 0
              },
            ],
          }
        ],
      },
    ]
  },
  'get:/tag': [
    {
      id: 1,
      name: '訂便當',
    },
    {
      id: 1,
      name: '下午茶',
    },
    {
      id: 1,
      name: '飲料',
    },
  ],
  'get:/search': [
    {
      id: 1,
      name: '水巷茶弄 - 台北長安店1',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.3
    },
    {
      id: 3,
      name: '水巷茶弄 - 台北長安店2',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.4
    },
    {
      id: 5,
      name: '水巷茶弄 - 台北長安店4',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.5
    },
  ],
  'get:/stores': [
    {
      id: 1,
      name: '水巷茶弄 - 台北長安店1',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.3
    },
    {
      id: 3,
      name: '水巷茶弄 - 台北長安店2',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.4
    },
    {
      id: 4,
      name: '水巷茶弄 - 台北長安店3',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.5
    },
    {
      id: 5,
      name: '水巷茶弄 - 台北長安店4',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.6
    }
  ],
  'get:/group/1': {
    name: '萬華商圈',
    stores: [
      {
        id: 1,
        name: '水巷茶弄 - 台北長安店1',
        open_time: '10:00~19:30',
        address: '台北市大同區長安西路126號',
        distance: 0.3
      },
      {
        id: 3,
        name: '水巷茶弄 - 台北長安店2',
        open_time: '10:00~19:30',
        address: '台北市大同區長安西路126號',
        distance: 0.4
      },
      {
        id: 4,
        name: '水巷茶弄 - 台北長安店3',
        open_time: '10:00~19:30',
        address: '台北市大同區長安西路126號',
        distance: 0.5
      },
      {
        id: 5,
        name: '水巷茶弄 - 台北長安店4',
        open_time: '10:00~19:30',
        address: '台北市大同區長安西路126號',
        distance: 0.6
      }
    ]
  },
  'get:/favorites': [
    {
      id: 1,
      name: '水巷茶弄 - 台北長安店1',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.3
    },
    {
      id: 2,
      name: '水巷茶弄 - 台北長安店2',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.4
    },
    {
      id: 3,
      name: '水巷茶弄 - 台北長安店3',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.5
    },
    {
      id: 4,
      name: '水巷茶弄 - 台北長安店4',
      open_time: '10:00~19:30',
      address: '台北市大同區長安西路126號',
      distance: 0.6
    }
  ],
  'get:/store/1/category': [
    {
      id: 1,
      name: '漢堡',
    },
    {
      id: 1,
      name: '吐司',
    },
    {
      id: 1,
      name: '蛋餅',
    },
    {
      id: 1,
      name: '鐵板麵',
    },
    {
      id: 1,
      name: '風味飲料',
    },
  ],
  'get:/store/1/product': [
    {
      id: 1,
      name: '豬肉滿福堡',
      img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
      info: '商品簡介',
      price: 55,
      options: [
        {
          name: '加料',
          values: [
            {
              name: '起司',
              price: 5
            }
          ],
        },
        {
          name: '口味',
          values: [
            {
              name: '加辣',
              price: 0
            },
            {
              name: '不加辣',
              price: 0
            },
            {
              name: '不加美乃滋',
              price: 0
            },
          ],
        }
      ]
    },
    {
      id: 1,
      name: '豬肉滿福堡2',
      img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
      info: '商品簡介',
      price: 55,
      options: [
        {
          name: '加料',
          values: [
            {
              name: '起司',
              price: 5
            }
          ],
        },
        {
          name: '口味',
          values: [
            {
              name: '加辣',
              price: 0
            },
            {
              name: '不加辣',
              price: 0
            },
            {
              name: '不加美乃滋',
              price: 0
            },
          ],
        }
      ]
    },
    {
      id: 1,
      name: '豬肉滿福堡3',
      img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
      info: '商品簡介',
      price: 55,
      options: [
        {
          name: '加料',
          values: [
            {
              name: '起司',
              price: 5
            }
          ],
        },
        {
          name: '口味',
          values: [
            {
              name: '加辣',
              price: 0
            },
            {
              name: '不加辣',
              price: 0
            },
            {
              name: '不加美乃滋',
              price: 0
            },
          ],
        }
      ]
    },
    {
      id: 1,
      name: '豬肉滿福堡4',
      img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
      info: '商品簡介',
      price: 55,
      options: []
    },
    {
      id: 1,
      name: '豬肉滿福堡5',
      img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
      info: '商品簡介',
      price: 55,
      options: [
        {
          name: '加料',
          values: [
            {
              name: '起司',
              price: 5
            }
          ],
        },
        {
          name: '口味',
          values: [
            {
              name: '加辣',
              price: 0
            },
            {
              name: '不加辣',
              price: 0
            },
            {
              name: '不加美乃滋',
              price: 0
            },
          ],
        }
      ]
    },
  ],
  'get:/categories/1/products': [
    {
      id: 1,
      name: '豬肉滿福堡a',
      img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
      info: '商品簡介',
      price: 55,
      options: [
        {
          name: '加料',
          values: [
            {
              name: '起司',
              price: 5
            }
          ],
        },
        {
          name: '口味',
          values: [
            {
              name: '加辣',
              price: 0
            },
            {
              name: '不加辣',
              price: 0
            },
            {
              name: '不加美乃滋',
              price: 0
            },
          ],
        }
      ]
    },
    {
      id: 1,
      name: '豬肉滿福堡b',
      img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
      info: '商品簡介',
      price: 55,
      options: [
        {
          name: '加料',
          values: [
            {
              name: '起司',
              price: 5
            }
          ],
        },
        {
          name: '口味',
          values: [
            {
              name: '加辣',
              price: 0
            },
            {
              name: '不加辣',
              price: 0
            },
            {
              name: '不加美乃滋',
              price: 0
            },
          ],
        }
      ]
    },
    {
      id: 1,
      name: '豬肉滿福堡c',
      img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
      info: '商品簡介',
      price: 55,
      options: []
    },
  ],
  'get:/product/1': {
    id: 1,
    name: '豬肉滿福堡a',
    img: 'https://images.unsplash.com/photo-1549611016-3a70d82b5040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1016&q=80',
    info: '商品簡介',
    price: 60,
    options: [
      {
        name: '加料',
        values: [
          {
            name: '起司',
            price: 5
          }
        ],
      },
      {
        name: '口味',
        values: [
          {
            name: '加辣',
            price: 0
          },
          {
            name: '不加辣',
            price: 0
          },
          {
            name: '不加美乃滋',
            price: 0
          },
        ],
      }
    ]
  },
  'get:/store/1': {
    id: 1,
    name: '水巷茶弄-台北長安店',
    intro: '天氣炎熱，歡迎前來喝杯涼的消消暑！',
    phone: '02-1234-5678',
    address: '台北市大同區長安西路126號',
    vat_number: '12345678',
    open_time: '本日營業10:00~19:30',
    holiday: '',
    device: 'WIFI、插座、吸菸區',
    description: '',
    busy_time: '15~20分',
    newses: [
      '預計10:00開始預訂！',
      '天氣炎熱，冰沙系列打9折，歡迎選購！'
    ],
    group: null
  },
  'get:/store/2': {
    id: 1,
    name: '水巷茶弄-台北長安店',
    intro: '天氣炎熱，歡迎前來喝杯涼的消消暑！',
    phone: '02-1234-5678',
    address: '台北市大同區長安西路126號',
    vat_number: '12345678',
    open_time: '本日營業10:00~19:30',
    holiday: '',
    device: 'WIFI、插座、吸菸區',
    description: '',
    busy_time: '15~20分',
    newses: [
      '預計10:00開始預訂！',
      '天氣炎熱，冰沙系列打9折，歡迎選購！'
    ],
    group: {
      id: 1,
      name: '萬華商圈',
    }
  },
  'get:/orders': [
    {
      id: 1,
      purchaser: '林先生',
      store_name: '點了沒早餐店1-台北店',
      pickup_time: '09/26(四) 19:00~19:30',
      status: '已接單'
    },
    {
      id: 1,
      purchaser: '林先生',
      store_name: '點了沒早餐店2-台北店',
      pickup_time: '09/26(四) 19:00~19:30',
      status: '已接單'
    },
    {
      id: 1,
      purchaser: '林先生',
      store_name: '點了沒早餐店3-台北店',
      pickup_time: '09/26(四) 19:00~19:30',
      status: '已接單'
    },
    {
      id: 1,
      store_name: '點了沒早餐店4-台北店',
      purchaser: '林先生',
      pickup_time: '09/26(四) 19:00~19:30',
      status: '已接單'
    },
  ],
  'get:/histories': [
    {
      id: 1,
      purchaser: '林先生',
      store_name: '點了沒早餐店1-台北店',
      pickup_time: '09/26(四) 19:00~19:30',
      status: '訂單完成'
    },
    {
      id: 1,
      purchaser: '林先生',
      store_name: '點了沒早餐店2-台北店',
      pickup_time: '09/26(四) 19:00~19:30',
      status: '訂單完成'
    },
    {
      id: 1,
      purchaser: '林先生',
      store_name: '點了沒早餐店3-台北店',
      pickup_time: '09/26(四) 19:00~19:30',
      status: '訂單完成'
    },
    {
      id: 1,
      store_name: '點了沒早餐店4-台北店',
      purchaser: '林先生',
      pickup_time: '09/26(四) 19:00~19:30',
      status: '訂單完成'
    },
  ],
  'get:/order/1': {
    id: 1,
    name: '個人訂單',
    store_name: '點了沒早餐店4-台北店',
    purchaser: '林先生',
    purchaser_phone: '0986-880888',
    phone: '02-1122-3344',
    address: '台北市大同區長安西路122號',
    created_at: '09/26(四) 08:35',
    pickup_time: '09/26(四) 19:00~19:30',
    pickup_type: '內用8號桌',
    pay_type: 'Apple Pay',
    vat_number: '',
    serial_number: '',
    status: '已接單',
    amount: 85,
    order_items: [
      {
        id: 1,
        name: '豬肉滿福堡1',
        quantity: 1,
        price: 55
      },
      {
        id: 1,
        name: '豬肉滿福堡2',
        quantity: 1,
        price: 55
      },
      {
        id: 1,
        name: '豬肉滿福堡3',
        quantity: 1,
        price: 55
      },
      {
        id: 1,
        name: '豬肉滿福堡4',
        quantity: 1,
        price: 55
      }
    ]
  },
}

const response = function (method, url) {
  return {
    code: () => 200,
    successful: () => true,
    data: () => api[method + ':' + url]
  }
}

const instance = {
  get: url => response('get', url),
  post: url => response('post', url),
  patch: url => response('patch', url),
  delete: url => response('delete', url),
}

export default instance
