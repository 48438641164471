import Vue from 'vue'
import VueRouter from 'vue-router'
// import { component } from 'vue/types/umd.js'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/user_notify',
    name: 'user_notify',
    component: () => import('../views/LoginBind.vue'),
  },
  // {
    // path: '/',
    // component: () => import('@/layouts/Store.vue'),
    // children: [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    // component: () => { window.location.href = "http://foodiebro.tech" },
  },
  {
    path: '/tag/:tag',
    name: 'tag',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/food',
    name: 'food',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/chain/:company',
    name: 'chain',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/group/:id',
    name: 'group',
    component: () => import('../views/Group.vue'),
  },
  {
    path: '/group/:group_id/store/:id',
    name: 'group_store',
    component: () => import('../views/GroupStore.vue'),
  },
  {
    path: '/store/:id',
    name: 'store',
    // component: () => import('../views/Store.vue'),
    component: () => import('../views/Redirect.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/Checkout.vue'),
  },
  {
    path: '/pay/:id',
    name: 'pay',
    component: () => import('../views/Pay.vue'),
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('../views/Order.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    // component: () => import('../views/Orders.vue'),
    component: () => import('../views/RedirectToOrders.vue'),
  },
  {
    path: '/pay_test/:id',
    name: 'pay_test',
    component: () => import('../views/PayTest.vue'),
  },
  {
    path: '/apple_pay/:id',
    name: 'apple_pay',
    component: () => import('../views/ApplePay.vue'),
  },
      // {
      //   path: '/store/:id',
      //   name: 'redirect_v2',
      //   component: () => import('../views/Redirect.vue'),
      // }
    // ]
  // },
  {
    path: '/menu_edit',
    name: 'menu_edit',
    component: () => import('../views/MenuEdit.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('to', to);
  // console.log('from', from);
  next();
  // if (to.name === 'store') {
  //   next()
  // } 

  // if (to.name === 'orders') {
  //   next()
  // }

  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   console.log('requiresAuth', to);
  //   const isLogin = localStorage.getItem('token') === 'ImLogin' ;
  //   if (isLogin && to.name != 'user_notify') {
  //     next({
  //       path: '/login',
  //       query: { redirect: to.fullPath }
  //     })
  //   } else {
  //     next()
  //   }
  // } else {
  //   next()
  // }
})

export default router
