import api from '@/plugins/http'

export default {
  namespaced: true,
  state: {
    group: {},
  },
  mutations: {
    UPDATE_GROUP (state, data) {
      state.group = data
    },
  },
  actions: {
    async getGroup ({ commit }, id) {
      try {
        const response = await api.get(`/group/${id}`)
        commit('UPDATE_GROUP', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
  }
}
