import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

import stores from './modules/stores'
import accounts from './modules/accounts'
import group from './modules/group'

Vue.use(Vuex)
Vue.use(VueCookies)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    stores, accounts, group
  }
})
