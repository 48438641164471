import axios from 'axios'
import mock from './mock-api'
import Cookies from 'js-cookie'

const token = () => {
  return Cookies.get('access_token')
}

const useMockApi = process.env.VUE_APP_USE_MOCK_API === 'true'

let instance

if (useMockApi) {
  instance = mock
} else {
  instance = axios.create({
    baseURL: process.env.VUE_APP_API_DOMAIN,
    timeout: 1000 * 60,
  })

  instance.defaults.headers.common['Authorization'] = `Bearer ${token()}`

  // http response 攔截器
  instance.interceptors.response.use(
    res => {
      return {
        code () {
          return res.status
        },
        successful () {
          return this.code() === 200
        },
        data () {
          if (this.successful()) {
            return res.data.data
          } else {
            return null
          }
        }
      }
    },
    async error => {
      return Promise.reject(error.response)
    }
  )
}

export default instance
